<template>
  <div>
    <b-table
      id="messageTable"
      :fields="fields"
      :items="messages"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      small
      hover
      bordered
      dark

      @row-clicked="onRowClicked"
    >

      <template v-slot:cell(message)="data">
        <div class="user">
          <b-avatar size="1.5em" variant="primary" class="mr-2">{{ data.item.from_name.charAt(0) }}</b-avatar>
          <b>{{ data.item.from_name }}</b>
          <b-badge variant="secondary" class="ml-2">{{ formatDate(data.item.timestamp) }}</b-badge>
        </div>
        <div class="message">
          <div style="white-space: pre-line">{{ formatMessage(data.item) }}</div>
        </div>
        <div class="media text-centre" mt-2>
          <a :href="data.item.link" target="_blank">
            <b-img v-if="data.item.type === 'image'" :src="data.item.link" fluid thumbnail></b-img>
            <b-img v-else-if="data.item.type === 'document'" src="https://findhse.cc/whapi/file?fileName=pdf.png" fluid thumbnail></b-img>
            <b-img v-else-if="data.item.type === 'video'" src="https://findhse.cc/whapi/file?fileName=video.png" fluid thumbnail></b-img>
            <audio v-else-if="data.item.type === 'voice'" controls :src=data.item.link></audio>
            <b-img v-else-if="data.item.type === 'link_preview'" src="https://findhse.cc/whapi/file?fileName=link.png" fluid thumbnail></b-img>
          </a>
        </div>
      </template>

    </b-table>

    <b-button id="loadMoreButton" @click="fetchMoreMessage" variant="primary" block>更多信息</b-button>
  </div> 

</template>

<script>
    import axios from 'axios';

    export default {
        name: 'MessageBody',
        props: {
          selectedGroup: {
            default: ''
          },
          timestamp: {
            default: ''
          },
        },
        components: {
        },
        data() {
          return {

            sortBy: 'timestamp',
            sortDesc: true,
            fields: [
              {
                key: 'message',
                label: '訊息',
                thStyle: { display: 'none' }
              }
            ],
            messages: [],
          }
        },
        async mounted() {
          //const messageResult = await this.fetchMessagesByGroup(this.selectedGroup);
          //this.messages = messageResult.data.messages;

        },
        watch: {
          selectedGroup: 
          {
            handler: async function() {
            const messageResult = await this.fetchMessagesByGroup(this.selectedGroup);
            this.messages = messageResult.data.messages;
            },
            immediate: true
          }
        },
        methods: {
          async fetchMessagesByGroup(group) {
            const result = await axios.get('https://findhse.cc/whapi/getmessages?groupid=' + group + "&timestamp=" + this.timestamp
              +'&limit=20&skip=0');
            return result;
          },
          onRowClicked(item) { 
            console.log(JSON.stringify(item));
            // if(item.link) {
            //   window
            //   .open(item.link, '_blank')
            //   .focus();
            // }
          },
          async fetchMoreMessage() {
              const result = await axios.get('https://findhse.cc/whapi/getmessages?groupid=' + this.selectedGroup + '&limit=20&skip=' + this.messages.length);
              if(result.data.messages.length === 0) {
                document.getElementById('loadMoreButton').innerText = '沒有更多信息';
                return;
              }
              else {
                this.messages = this.messages.concat(result.data.messages);
              }
          },
          formatDate(date) {
            return new Date(date * 1000 + + 8 * 60 * 60 * 1000).toISOString().substr(0, 19).replace('T', ' ');
          },
          formatMessage(message) {
            return message.message;
          },
        }
    }
</script>