import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import store from "../../store";
import { BootstrapVue } from 'bootstrap-vue'
import { NavbarPlugin } from 'bootstrap-vue'
import VueFeather from 'vue-feather'
import api from "../../services/UserService";

Vue.config.productionTip = false

console.log(Vue.version);

Vue.use(BootstrapVue);
Vue.use(NavbarPlugin);
Vue.use(VueFeather);
Vue.use(VueRouter);

//routes
const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    name: "Login",
    component: () => import("../../views/AppLogin.vue"),
    props: { name: "Price Monitor" }
  },
  {
    path: "/message",
    name: "Message",
    component: () => import("../../views/Message.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../../views/Dashboard.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/group",
    name: "Group",
    component: () => import("../../views/Group.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/symbol",
    name: "Symbol",
    component: () => import("../../views/Symbol.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/server",
    name: "Server",
    component: () => import("../../views/Server.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/holiday",
    name: "Holiday",
    component: () => import("../../views/Holiday.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/user",
    name: "User",
    component: () => import("../../views/User.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/log",
    name: "Log",
    component: () => import("../../views/Log.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/Price/:server/:symbol",
    name: "Price",
    props: true,
    component: () => import("../../views/Price.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/newPropertyRecord",
    name: "NewPropertyRecord",
    component: () => import("../../views/NewPropertyRecord.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../../views/News.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/propertyGenerator",
    name: "PropertyGenerator",
    component: () => import("../../views/PropertyGenerator.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/newProperties",
    name: "NewProperties",
    component: () => import("../../views/NewProperties.vue"),
    meta: {
      requiresAuth: true
    }
  }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let loggedIn = store.state.session.loggedIn;
  if (loggedIn) { 
    if (window.event.type == 'popstate'){ 
      // disable back button
      window.history.forward(1)

      // if it is Message, then hide modal
      if(from.matched.some(record => record.name == "Message")) {
        router.app.$root.$emit('bv::hide::modal', 'modal-1');
      }
      return false;
    }
    else if(to.matched.some(record => record.name == "Login")) {
      api
      .post("/api/logout")
      .then(response => {
        store.commit("mutateSessionLogout");
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
        store.commit("mutateSessionLogout");
      });
    }
    next();
  }
  else if(to.matched.some(record => record.meta.requiresAuth)) {
    next({ name: 'Login' });
  }
  else {
    next();
  }
})

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

