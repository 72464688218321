<template>
  <div>
    <section id="cover" class="min-vh-100">
      <div id="cover-caption">
        <div class="container">
          <div class="row text-white">
            <div
              class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4"
            >
              <h1 class="py-2 text-truncate">{{ name }}</h1>
              <div class="px-2">
                <form class="justify-content-center" @submit.prevent="onLogin">
                  <div class="form-group">
                    <label>用戶名稱</label><br />
                    <input
                      type="text"
                      class="form-control"
                      v-model="username"
                      required
                      @focus="incorrect = false"
                    />
                  </div>
                  <div class="form-group">
                    <label>密碼</label><br />
                    <input
                      type="password"
                      class="form-control"
                      v-model="password"
                      @focus="incorrect = false"
                    />
                  </div>
                  <button type="submit" class="btn btn-primary btn-lg">
                    登入
                  </button>
                </form>

                <b-alert
                  class="mt-2"
                  show
                  dismissible
                  variant="danger"
                  v-if="incorrect"
                >
                  <strong>{{ username }}</strong> Incorrect username or password
                </b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "../services/UserService";
export default {
  props: ["name"],
  data() {
    return {
      loading: true,
      incorrect: false,
      username: "",
      password: ""
    };
  },
  methods: {
    onLogin() {
      this.loading = true;
      this.incorrect = false;
      api
        .post("/api/login", { username: this.username, password: this.password })
        .then(response => {
          this.$store.commit("mutateSessionLogin", {
            user: response.data
          });
          this.loading = false;
          this.$router.push({ name: 'Message' });
        })
        .catch(error => {
          console.log(error);
          this.incorrect = true;
          this.$store.commit("mutateSessionLogout");
        });
    }
  },
  mounted: function() {
  }
};
</script>

<style scoped>
#cover {
  background: #222 url("https://unsplash.it/1920/1080/?random") center center
    no-repeat;
  background-size: cover;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
}

#cover-caption {
  width: 100%;
  position: relative;
  z-index: 1;
}

/* only used for background overlay not needed for centering */
form:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
  border-radius: 10px;
}
</style>
