<template>
  <div id="app" class="bg-dark">
    <TopMenu/>
    <b-overlay :show="loading" rounded="sm" spinner-variant="primary">
      <b-container fluid class="bg-dark pb-5">
        <div>
          <b-row>
            <b-col>
              <b-form-select v-model="selectedArea" :options="areaOptions" class="bg-dark text-white" v-on:change="onChangedArea"></b-form-select>
            </b-col>
            <b-col>
              <b-form-select v-model="selectedSource" :options="sourceOptions" class="bg-dark text-white"></b-form-select>
            </b-col>
            <b-col>
              <b-form-select v-model="selectedType" :options="typeOptions" class="bg-dark text-white" v-on:change="onChangeType"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <!-- checkbox to filter out non updated-->
            <b-col>
              <b-form-select v-model="selectedDistrict" :options="districtOptionsComputed" class="bg-dark text-white" v-on:change="onChangedDistrict"></b-form-select>
            </b-col>
            <b-col>
              <b-form-select v-model="selectedSchool" :options="schoolOptionsComputed" class="bg-dark text-white"></b-form-select>
            </b-col>
            <b-col>
              <b-form-select v-model="selectedRoom" :options="roomOptions" class="bg-dark text-white"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <!-- checkbox to filter out non updated-->
            <b-col>
              <b-form-checkbox v-model="checkedNewOnly" class="bg-dark text-white">現存放盤</b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="checkedDiscountOnly" class="bg-dark text-white" v-on:change="onChangeDiscount">減價盤</b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="checkedTodayOnly" class="bg-dark text-white" v-on:change="onChangeTodayOnly">今天放盤</b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="checkedTodayDiscount" class="bg-dark text-white" v-on:change="onChangeTodayDiscount">今天減價</b-form-checkbox>
            </b-col>          
          </b-row>
          <b-row class="mt-2 ">
            <b-col>
              <b-form-input v-model="lowerPrice" placeholder="價錢下限" type="number" class="bg-dark text-white"></b-form-input>
            </b-col>
            <b-col>
              <b-form-input v-model="upperPrice" placeholder="價錢上限" type="number" class="bg-dark text-white"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-form-input v-model="lowerArea" placeholder="面積下限" type="number" class="bg-dark text-white"></b-form-input>
            </b-col>
            <b-col>
              <b-form-input v-model="upperArea" placeholder="面積上限" type="number" class="bg-dark text-white"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-form-input v-model="lowerAge" placeholder="樓齡下限" type="number" class="bg-dark text-white"></b-form-input>
            </b-col>
            <b-col>
              <b-form-input v-model="upperAge" placeholder="樓齡上限" type="number" class="bg-dark text-white"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-input class="ml-2 mr-2" placeholder="Filter..." v-model="filter"></b-input>
          </b-row>
        </div>
        <div class="mt-3 bg-dark">
            <b-table
              v-if="!loading"
              id="fullTable"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :items="displayRecords"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              small
              hover
              bordered
              dark

              @row-clicked="onRowClicked"
            >
              <template #cell(title)="data">
                <span>{{data.item.ti}}</span>
              </template>
              <template #cell(propertyDistrict)="data">
                {{ data.item.d }}
              </template>
              <template #cell(room)="data">
                {{ data.item.r  }}
              </template>
              <template #cell(ua)="data">
                <span :style="{ color: data.item.ua > 0 ? 'inherit' : 'red'  }">{{ data.item.ua > 0 ? data.item.ua : data.item.b }}</span>
              </template>
              <template #cell(price)="data">
                <span>{{data.item.p}}</span>
              </template>
              <template #cell(fp)="data">
                {{data.item.fp}}
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="displayRecords.length"
              :per-page="perPage"
              aria-controls="fullTable"
              align="center"
              class="mt-3"
            ></b-pagination>
        </div>
      </b-container>
    </b-overlay>
    <b-modal id="modal-1" title="樓盤詳情" ok-only>
      <div class="d-block text-center">
        <b-container>
            <b-row class="top">
              <b-col cols="4">
                <b-card-text>樓盤</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.ti}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>物業編號</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.id}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>地區</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.d}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>校網</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.sc}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>特色</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.features? record.features.join(","):""}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>房間數目</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.r}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>實用面積</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.ua}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>建築面積</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.b}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>價錢</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.p}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="solid">
              <b-col cols="4">
                <b-card-text>尺價</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{calPricePerArea(record.t, record.ua > 0 ? record.ua:record.b, record.p)}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="bottom">
              <b-col cols="4">
                <b-card-text>樓齡</b-card-text>
              </b-col>
              <b-col>
                <b-card-text>{{record.a}}</b-card-text>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <!-- button to display either price History or transaction history-->
                <b-button variant="primary" @click="isShowPriceHistory=true">價錢走勢</b-button>
              </b-col>
              <b-col>
                <b-button variant="primary" @click="isShowPriceHistory=false">歷史呎價</b-button>
              </b-col>
            </b-row>  
            <b-row v-if="isShowPriceHistory">
              <LineChart :priceHistory="record.priceHistory" :title="'價錢走勢'" />
            </b-row>
            <b-row v-else>
              <LineChart :priceHistory="transactionDataPlot" :title="'歷史呎價'"/>
            </b-row>
            <b-row class="mt-1">
              <b-col style="padding-left: 0%; padding-right: 0%">
                <b-button size="sm" variant="success" :href="record.h" target="_blank">原網連結</b-button>
              </b-col>
              <b-col style="padding-left: 0%; padding-right: 0%">
              <b-button size="sm" id="valuationbtn" variant="danger" @click="propertyEstimate(record.s, record.id, record.h)">物業估價</b-button>
              </b-col>
              <b-col style="padding-left: 0%; padding-right: 0%">
                <b-button size="sm" id="downloadbtn" variant="info" @click="downloadImages(record.s, record.h, record.id)">下載圖片</b-button>
              </b-col>
              <b-col style="padding-left: 0%; padding-right: 0%">
                <b-button size="sm" variant="primary" @click="$root.$emit('bv::show::modal', 'modal-2')">歷史成交</b-button>
              </b-col>
            </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal id="modal-2" title="歷史成交" ok-only>
      <div class="d-block text-center">
        <b-container>
          <b-row class="top">
            <b-col cols="2">
              <!--bold the title text-->
              <b-card-text>成交</b-card-text>
            </b-col>
            <b-col cols="2">
              <b-card-text>實積</b-card-text>
            </b-col>
            <b-col cols="2">
              <b-card-text>建積</b-card-text>
            </b-col>
            <b-col cols="2">
              <b-card-text>呎價</b-card-text>
            </b-col>
            <b-col cols="4">
              <b-card-text>日期</b-card-text>
            </b-col>
          </b-row>
          <b-row class="solid" v-for="transactionRecord in transactionHistory" v-bind:key="transactionRecord.id">
            <b-col cols="12">
              <b-card-text>{{transactionRecord.title}}</b-card-text>
            </b-col>
              <b-col cols="2">
                <b-card-text>{{transactionRecord.price}}</b-card-text>
              </b-col>
              <b-col cols="2">
                <b-card-text>{{transactionRecord.usableArea}}</b-card-text>
              </b-col>
              <b-col cols="2">
                <b-card-text>{{transactionRecord.buildArea}}</b-card-text>
              </b-col>
              <b-col cols="2">
                <b-card-text>{{transactionRecord.pricePerArea}}</b-card-text>
              </b-col>
              <b-col cols="4">
                <b-card-text>{{transactionRecord.date}}</b-card-text>
              </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import TopMenu from '../components/TopMenu.vue'
import LineChart from '../components/LineChart.vue'

import axios from 'axios'
import api from "../services/UserService";

export default {
  name: 'App',
  components: {
    TopMenu,
    LineChart
},
  data() {
      return {
          currentRecords: [],
          filter: '',
          selectedArea: '新界西',
          selectedType: '買賣',
          selectedSource: '所有',
          selectedDistrict: [],
          selectedSchool: 0,
          selectedRoom: -1,
          lowerPrice: '',
          upperPrice: '',
          lowerArea: '',
          upperArea: '',
          lowerAge: '',
          upperAge: '',
          checkedNewOnly: true,
          checkedTodayOnly: true,
          checkedDiscountOnly: false,
          checkedTodayDiscount: false,
          currentPage: 1,
          perPage: 20,
          areaOptions: [
            {value: '九龍', text: '九龍'},
            {value: '新界西', text: '新界西'},
            {value: '新界東', text: '新界東'},
            {value: '港島', text: '港島'},
          ],
          typeOptions: [
            {value: '買賣', text: '買賣'},
            {value: '租賃', text: '租賃'}
          ],
          sourceOptions: [
            {value: '利嘉閣', text: '利嘉閣'},
            {value: '中原', text: '中原'},
            {value: '美聯', text: '美聯'},
            {value: '港置', text: '港置'},
            {value: '28hse', text: '28hse'},
            {value: '所有', text: '所有'},
          ],
          districtOptions: [
            {value: [34,35,41], text: '九龍城區', area: '九龍'},
            {value: [31,32], text: '油尖旺區', area: '九龍'},
            {value: [40], text: '深水埗區', area: '九龍'},
            {value: [43,45], text: '黃大仙區', area: '九龍'},
            {value: [46,48], text: '觀塘區', area: '九龍'},
            {value: [62], text: '荃灣', area: '新界西'},
            {value: [64,65,66], text: '葵青', area: '新界西'},
            {value: [70,71], text: '屯門', area: '新界西'},
            {value: [72,73,74], text: '元朗', area: '新界西'},
            {value: [96,97,98,99], text: '離島', area: '新界西'},
            {value: [80,81,83], text: '北區', area: '新界東'},
            {value: [84], text: '大埔', area: '新界東'},
            {value: [88,89,91], text: '沙田', area: '新界東'},
            {value: [95], text: '西貢', area: '新界東'},
            {value: [14,16], text: '東區', area: '港島'},
            {value: [18], text: '南區', area: '港島'},
            {value: [12], text: '灣仔區', area: '港島'},
            {value: [11], text: '中西區', area: '港島'}
          ],
          schoolOptions: [
            {value: 11, text: '中西區 11', district: '中西區', area: '港島'},
            {value: 12, text: '灣仔區 12', district: '灣仔區', area: '港島'},
            {value: 14, text: '東區 14', district: '東區', area: '港島'},
            {value: 16, text: '東區 16', district: '東區', area: '港島'},
            {value: 18, text: '南區 18', district: '南區', area: '港島'},
            {value: 31, text: '油尖旺區 31', district: '油尖旺區', area: '九龍'},
            {value: 32, text: '油尖旺區 32', district: '油尖旺區', area: '九龍'},
            {value: 34, text: '九龍城區 34', district: '九龍城區', area: '九龍'},
            {value: 35, text: '九龍城區 35', district: '九龍城區', area: '九龍'},
            {value: 41, text: '九龍城區 41', district: '九龍城區', area: '九龍'},
            {value: 40, text: '深水埗區 40', district: '深水埗區', area: '九龍'},
            {value: 43, text: '黃大仙區 43', district: '黃大仙區', area: '九龍'},
            {value: 45, text: '黃大仙區 45', district: '黃大仙區', area: '九龍'},
            {value: 46, text: '觀塘區 46', district: '觀塘區', area: '九龍'},
            {value: 48, text: '觀塘區 48', district: '觀塘區', area: '九龍'},
            {value: 62, text: '荃灣 62', district: '荃灣', area: '新界西'},
            {value: 64, text: '葵青 64', district: '葵青', area: '新界西'},
            {value: 65, text: '葵青 65', district: '葵青', area: '新界西'},
            {value: 66, text: '葵青 66', district: '葵青', area: '新界西'},
            {value: 70, text: '屯門 70', district: '屯門', area: '新界西'},
            {value: 71, text: '屯門 71', district: '屯門', area: '新界西'},
            {value: 72, text: '元朗 72', district: '元朗', area: '新界西'},
            {value: 73, text: '元朗 73', district: '元朗', area: '新界西'},
            {value: 74, text: '元朗 74', district: '元朗', area: '新界西'},
            {value: 96, text: '離島 96', district: '離島', area: '新界西'},
            {value: 97, text: '離島 97', district: '離島', area: '新界西'},
            {value: 98, text: '離島 98', district: '離島', area: '新界西'},
            {value: 99, text: '離島 99', district: '離島', area: '新界西'},
            {value: 80, text: '北區 80', district: '北區', area: '新界東'},
            {value: 81, text: '北區 81', district: '北區', area: '新界東'},
            {value: 83, text: '北區 83', district: '北區', area: '新界東'},
            {value: 84, text: '大埔 84', district: '大埔', area: '新界東'},
            {value: 88, text: '沙田 88', district: '沙田', area: '新界東'},
            {value: 89, text: '沙田 89', district: '沙田', area: '新界東'},
            {value: 91, text: '沙田 91', district: '沙田', area: '新界東'},
            {value: 95, text: '西貢 95', district: '西貢', area: '新界東'},
          ],
          usableAreaOptions: [
            {value: 0, text: '不限尺數'},
            {value: 200, text: '200呎'},
            {value: 300, text: '300呎'},
            {value: 400, text: '400呎'},
            {value: 500, text: '500呎'},
            {value: 600, text: '600呎'},
            {value: 700, text: '700呎'},
            {value: 800, text: '800呎'},
            {value: 900, text: '900呎'},
            {value: 1000, text: '1000呎'},
            {value: 1100, text: '1100呎'},
            {value: 1200, text: '1200呎'},
            {value: 1300, text: '1300呎'},
            {value: 1400, text: '1400呎'},
            {value: 1500, text: '1500呎'}
          ],
          roomOptions: [
            {value: -1, text: '不限房數'},
            {value: 0, text: '開放式'},
            {value: 1, text: '1房'},
            {value: 2, text: '2房'},
            {value: 3, text: '3房'},
            {value: 4, text: '4房'},
            {value: 5, text: '5房或以上'},
          ],
          sortBy: '',
          sortDesc: false,
          fields: [
            {
                key:"ti",
                label: '樓盤',
                sortable: false,

            },
            {
                key:"d",
                label: '地區',
                sortable: true,
            },
            {
                key:"r",
                label: '房',
                sortable: true,
            },
            {
                key:"ua",
                label: '實',
                sortable: true,
            },
            {
                key:"p",
                label: '現價',
                sortable: true,
                tdAttr: (value , key, item ) => {
                  return this.getColorCell(item.p, item.fp);
                },
            },
            {
                key:"de",
                label: '市比',
                sortable: true,
                sortByFormatted: true,
                formatter: (value) => {
                  return value*-1;
                },
            },
            {
                key:"a",
                label: '樓齡',
                sortable: true,
            },
            {
                key:"pricePerArea",
                label: '尺價',
                sortable: true,
                tdAttr: (value , key, item ) => {
                  return this.getSourceColorCell(item.s);
                },
                // special sort function
                sortByFormatted: true,
                formatter: (value, key, item) => {
                  if(item.ua > 0)
                  {
                    return this.calPricePerArea(item.t, item.ua, item.p);
                  }
                  else
                  {
                    return this.calPricePerArea(item.t, item.b, item.p);
                  }

                }
            }
          ],
          //propertyRecords: [],
          loading: false,
          record: {
            id: 'bu89159055', // propertyId
            area: "新界西", // propertyArea
            d: "錦田", // propertyDistrict
            t: '買賣', // propertyType
            s: '利嘉閣',  // source
            ti: '爾巒 菲利提諾大道 7座 3房  高層', // title
            r: 3, // room
            ua: 649, // usableArea
            b: 835, // buildArea
            p: 828, // price
            h: 'https://www.ricacorp.com/zh-hk/property/detail/%E9%8C%A6%E7%94%B0-hma-%E7%88%BE%E5%B7%92-%E8%8F%B2%E5%88%A9%E6%8F%90%E8%AB%BE%E5%A4%A7%E9%81%93-7%E5%BA%A7-bu89159055-3-hk', // href
            sc: 62, // school
            a: 10, // age
            lu:'2023-09-24T10:04:50.556Z', // lastUpdate
            priceHistory: [
              {
                price: 828,
                date: '2023-09-24 00:00:00'
              },
              {
                price: 828,
                date: '2023-09-23 00:00:00'
              }
            ]
          },
          transactionDataPlot: [],
          transactionHistory: [],
          isShowPriceHistory: true,
      }
  },
  async mounted() {
    await this.fetchPropertyRecords();
  },
  setup () {
    console.log("I am in setup!!!")
  },
  watch: {
      checkedNewOnly: async function(){
        if(!this.checkedNewOnly)
        {
          this.checkedTodayOnly = false;
          this.checkedDiscountOnly = false;
          this.checkedTodayDiscount = false;
        }
        await this.fetchPropertyRecords();
      }
  },
  computed: {
    displayRecords() {
      const filterWord = this.filter.toLowerCase();
      return this.$store.getters.getPropertyByFilter(this.selectedArea, this.selectedType, this.checkedNewOnly)
       .filter( r=> (this.filterWords(filterWord, r.ti, r.p, r.ua, r.d, r.id) &&
         (this.selectedSource == '所有' || r.s == this.selectedSource) &&
         r.area == this.selectedArea &&
         r.t == this.selectedType &&
         (!this.checkedTodayOnly || r.to) &&
        (!this.checkedDiscountOnly || r.p < r.fp) &&
        (!this.checkedTodayDiscount || r.td) &&
        (this.lowerPrice == '' || r.p >= this.lowerPrice) &&
        (this.upperPrice == '' || r.p <= this.upperPrice) &&
        (this.lowerArea == '' || r.ua >= this.lowerArea) &&
        (this.upperArea == '' || r.ua <= this.upperArea) &&
        (this.lowerAge == '' || r.a >= this.lowerAge) &&
        (this.upperAge == '' || r.a <= this.upperAge) &&
        (this.selectedSchool == 0 || r.sc == this.selectedSchool) &&
        (this.selectedDistrict.length == 0 || this.selectedDistrict.includes(r.sc)) &&
        (this.selectedRoom == -1 || this.selectedRoom == 5 && r.r >=5 || r.r == this.selectedRoom)
        )
      )
   },
   priceOptionsComputed() {
    if(this.selectedType == '租賃') {
      return [
            {value: 0, text: '不限價錢'},
            {value: 8000, text: '8000'},
            {value: 9000, text: '9000'},
            {value: 10000, text: '10000'},
            {value: 11000, text: '11000'},
            {value: 12000, text: '12000'},
            {value: 13000, text: '13000'},
            {value: 14000, text: '14000'},
            {value: 15000, text: '15000'},
            {value: 16000, text: '16000'},
            {value: 17000, text: '17000'},
            {value: 18000, text: '18000'},
            {value: 19000, text: '19000'},
            {value: 20000, text: '20000'},
            {value: 21000, text: '21000'},
            {value: 22000, text: '22000'},
            {value: 23000, text: '23000'},
            {value: 24000, text: '24000'},
            {value: 25000, text: '25000'}
          ];
    }
    else {
      return [
            {value: 0, text: '不限價錢'},
            {value: 100, text: '100萬'},
            {value: 200, text: '200萬'},
            {value: 300, text: '300萬'},
            {value: 400, text: '400萬'},
            {value: 500, text: '500萬'},
            {value: 600, text: '600萬'},
            {value: 700, text: '700萬'},
            {value: 800, text: '800萬'},
            {value: 900, text: '900萬'},
            {value: 1000, text: '1000萬'},
            {value: 1100, text: '1100萬'},
            {value: 1200, text: '1200萬'},
            {value: 1300, text: '1300萬'},
            {value: 1400, text: '1400萬'},
            {value: 1500, text: '1500萬'}            
          ];
    }
   },
    districtOptionsComputed() {
      var options = this.districtOptions.filter( d=> d.area == this.selectedArea);
      options.unshift({value: [], text: '不限地區'});
      return options;
    },
    schoolOptionsComputed() {
      var options = this.schoolOptions.filter( s=> s.area == this.selectedArea)
      .filter( s=> this.selectedDistrict.length == 0 || this.selectedDistrict.includes(s.value));
      options.unshift({value: 0, text: '不限學校'});
      return options;
    }
  },
  methods: {
    showModal() {
      console.log(this.record);
      //console.log(this.transactionHistory);
      //console.log(this.transactionDataPlot);
      this.$root.$emit('bv::show::modal', 'modal-1');
    },
    async onRowClicked(item) {
      this.isShowPriceHistory = true;
      this.record = await this.fetchRecord(item.t, item.id);
      this.transactionHistory = await this.fetchTransactionHistory(item.t, item.id);
      this.transactionHistory.forEach( r => {
        r.pricePerArea = r.usableArea>0 ? this.calPricePerArea(r.propertyType, r.usableArea, r.price) :
        this.calPricePerArea(r.propertyType, r.buildArea, r.price);
        r.date = new Date(r.date).toISOString().split('T')[0];
      });
      // sort by date in accending order
      this.transactionHistory = this.transactionHistory.sort((a, b) => new Date(a.date) - new Date(b.date));
      this.transactionDataPlot = this.transactionHistory.map( r => {
        return {date: r.date, price: r.pricePerArea}
      });
      // sort transactionHistory in descending order
      this.transactionHistory = this.transactionHistory.reverse();

      this.showModal();
    },
    filterWords(filters, title, price, usableArea, district, id) {
      var filterArray = filters.split(" ");
      for (var i = 0; i < filterArray.length; i++) {
        const filter = filterArray[i].trim().toLowerCase();
        if (filter == '') continue;
        else if (title && title.toLowerCase().includes(filter)) continue;                                                                                     
        else if (price && price.toString().includes(filter)) continue;
        else if (usableArea && usableArea.toString().                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     includes(filter)) continue;
        else if (district && district.toLowerCase().includes(filter)) continue;
        else if (id && id.toLowerCase().includes(filter)) continue;
        else return false;
      }
      return true;
    },
    propertyEstimate(source, id, link) {
      document.getElementById('valuationbtn').innerText = "估價中..";
      document.getElementById('valuationbtn').disabled = true;
      if (source == "中原")
      {
        // http get 'https://hk.centanet.com/findproperty/api/Bank/EvaluateProperty?refno={id}&bankCode=012' with payload {bankCode: "012", refno: id}
        // then get response with format {hasValuation: true, valuationDate: "2023-10-24T00:00:00", valuationDateDisplay: "2023-10-24", valuationPrice: 7690000}
        // then show valuationPrice
        axios.get('https://hk.centanet.com/findproperty/api/Bank/EvaluateProperty?refno=' + id + '&bankCode=012', { data: {bankCode: "012", refno: id} })
        .then(response => {
          console.log(response.data);
          if (response.data.hasValuation)
          {
            document.getElementById('valuationbtn').innerText = "估價: " + response.data.valuationPrice / 10000 + '萬';
          }
          else
          {
            document.getElementById('valuationbtn').innerText = "無估價";
          }
        })
        .catch(error => {
          console.log(error);
          document.getElementById('valuationbtn').innerText = "估價錯誤";
        })
        .then(() => {
          document.getElementById('valuationbtn').disabled = false;
        });
      }
      else if (source == "美聯" || source == "利嘉閣" || source == "港置")
      {
        api.post('/api/valuation', {
          url: link
        }).then(response => {
          if (response.data.valuationPrice > 0)
          {
            document.getElementById('valuationbtn').innerText = "估價: " + response.data.valuationPrice + '萬';
          }
          else
          {
            document.getElementById('valuationbtn').innerText = "無估價";
          }
        })
        .catch(error => {
          console.log(error);
          document.getElementById('valuationbtn').innerText = "估價錯誤";
        })
        .then(() => {
          document.getElementById('valuationbtn').disabled = false;
        });
      }
      else {
        document.getElementById('valuationbtn').innerText = "未支援估價";
        document.getElementById('valuationbtn').disabled = false;
      }
    },
    async pause(msec) {
      return new Promise(
          (resolve) => {
              setTimeout(resolve, msec || 1000);
          }
        );
    },
    async downloadImages(source, url, id) {

      try{
        document.getElementById('downloadbtn').innerText = "下載中..";
        document.getElementById('downloadbtn').disabled = true;

        const response = await api.get(`/api/grabPhotos?source=${source}&url=${url}&id=${id}`);        

        for (var e in response.data.photos) {
          const element = response.data.photos[e];
          var link = document.createElement('a');
          link.href = '/api/getphoto?source=' + source + '&id=' + id + '&name=' + element;
          link.download = id + "_" + element;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          await this.pause(500);
        }

      } catch (error) {
        console.log(error);
        alert('下載失敗');
      }
      finally{
        document.getElementById('downloadbtn').innerText = "下載圖片";
        document.getElementById('downloadbtn').disabled = false;
      }
    },
    async fetchRecord(type, id) {
      try{
        const response = await api.get(`/api/getPropertyRecord?type=${type}&id=${id}`);
        return response.data.propertyRecord;
      } catch (error) {
        console.log(error);
      }
      return {};
    },
    async fetchTransactionHistory(type, id) {
      try{
        const response = await api.get(`/api/getTransactionHistory?type=${type}&id=${id}`);
        return response.data.transactions;
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    async fetchPropertyRecords() {
      if (this.$store.getters.getPropertyByFilter(this.selectedArea, this.selectedType, this.checkedNewOnly).length > 0)
      {
        console.log('already fetched ' + this.$store.getters.getPropertyByFilter(this.selectedArea, this.selectedType, this.checkedNewOnly).length + ' records');
        return;
      }
      else{
        console.log('start fetchPropertyRecords: ' + this.selectedArea + ' ' + this.selectedType);
      }

      // print loading time
      var start = new Date();
      this.loading = true;

      // wait for fetchRecords
      await this.$store.dispatch('fetchRecords', {'type': this.selectedType, 'area': this.selectedArea, 'latest': this.checkedNewOnly}).then(() => {
          console.log('fetched ' + this.$store.getters.getPropertyByFilter(this.selectedArea, this.selectedType, this.checkedNewOnly).length + ' records');
      }).catch((error) => {
          console.log('error: ' + error);
      }).finally(() => {
          var end = new Date();
          console.log('fetchRecords took ' + (end.getTime() - start.getTime()) + ' ms');  
          this.loading = false;
      });


    },
    async onChangeType()
    {
      await this.fetchPropertyRecords();
    },
    async onChangedArea()
    {
      this.selectedDistrict = [];
      this.selectedSchool = 0;
      await this.fetchPropertyRecords();
    },
    async onChangedDistrict()
    {
      this.selectedSchool = 0;
    },
    async onChangeDiscount()
    {
      if (!this.checkedDiscountOnly) this.checkedTodayOnly = false;
    },
    async onChangeTodayOnly()
    {
      if (!this.checkedTodayOnly) 
      {
        this.checkedTodayDiscount = false;
        this.checkedDiscountOnly = false;
      }
    },
    async onChangeTodayDiscount()
    {
      if (!this.checkedTodayDiscount) this.checkedTodayOnly = false;
    },
    getColorCell(currentPrice, firstPrice) {
      if(currentPrice > firstPrice) return { bgcolor: 'red', color: 'white'};
      else if(currentPrice < firstPrice) return { bgcolor: 'green', color: 'white'};
      else return { };
    },
    getSourceColorCell(source) {
      if(source == '中原') return { bgcolor: 'red', color: 'white'};
      else if(source == '美聯') return { bgcolor: '#F1C40F', color: 'white'};
      else if(source == '利嘉閣') return { bgcolor: 'blue', color: 'white'};
      else if(source == '港置') return { bgcolor: 'green', color: 'white'};
      else if(source == '28hse') return { bgcolor: '#9FE2BF', color: 'red'};
      else return { };
    },
    formatDate(date) {
      return date.substr(0,10);
    },
    calPricePerArea(type, area, price) {
      if(area == 0) return 0;
      if(type == '租賃') {
        return Math.round(price / area);
      } else {
        return Math.round(price / area * 10000);
      }
    }

  }
}
</script>

<style>
  @import '../assets/styles/global.css';

  .sticky {
    position:-webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #333333;
  }

  .table.b-table>thead>tr>th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  background-color: #333333;
  } 

  body {
    overscroll-behavior: none;
  }





</style>