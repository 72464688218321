<template>
    <div id="app" class="bg-dark" >
      <TopMenu/>
      <iframe src="/static/news" style="width: 100%; height: 100vh; border: none;"></iframe>
  
    </div>
  </template>
  
  <script>
  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap-vue/dist/bootstrap-vue.css'

  import TopMenu from '../components/TopMenu.vue'
  
  export default {
    name: 'App',
    components: {
        TopMenu
  },
    data() {
        return {
            filter: '',
            loading: false,
        }
    },
    async mounted() {
    },
    beforeDestroy () {
    },
  
    watch: {
    },
    computed: {  
    },
    methods: {
    }
  }
  </script>
  
  <style>
    @import '../assets/styles/global.css';
  
  
  </style>