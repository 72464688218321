<template>
  <div id="app" class="bg-dark" >
    <TopMenu/>
    <b-overlay :show="loading" rounded="sm" spinner-variant="primary">
      <b-container fluid class="bg-dark pb-5 ">

        <div class= "pt-2 sticky-top bg-dark" >
          <b-row>
            <!-- top header -->
            <b-col>
              <h3 class="text-white">Messages</h3>
            </b-col>
          </b-row>
          <b-row v-if="!isViewingMessage" class="pt-2 pb-2">
            <b-col cols="9">
              <b-input style="width: 100%" class="ml-2 mr-2" placeholder="Find..." v-model="filter"></b-input>
            </b-col>
            <b-col cols="3">
              <b-button style="width: 100%" :variant="searchBtnClass" id="searchBtn" @click="findMessage">{{ searchBtnText }}</b-button>              
            </b-col>
          </b-row>
          <b-row class="pt-2 pb-2">
            <!-- add a back button when isViewingMessage == true -->
            <b-col v-if="isViewingMessage" cols="12">

              <b-button variant="secondary" @click="onBackToGroups"><feather type="skip-back"></feather> Back to Groups</b-button>

            </b-col>
          </b-row>
        </div>

        <div class="pt-1 bg-dark">
          <b-table
            dark
            striped
            hover
            :items="groupOptions"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @row-clicked="onRowClicked"
            v-if="!isViewingMessage"
          >
            <template v-slot:cell(name)="data">
              <div>
                <b>{{ data.item.text }}</b>
                <!--put time at the right side as batch-->
                <b-badge variant="secondary" class="ml-2">{{ formatDate(data.item.timestamp) }}</b-badge>
                <b-badge v-if="data.item.isNewMessage" variant="danger" class="ml-2">New</b-badge>
              </div>
              <div class="text-muted"><small>{{ formatSubText(data.item.message) }}</small></div>
            </template>
          </b-table>

        </div>

        <div class="pt-1 bg-dark">
          <MessageBody v-if="isViewingMessage" :selectedGroup="selectedGroup" :timestamp="messageTime"></MessageBody>
        </div>

      </b-container>
    </b-overlay>
 

  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import TopMenu from '../components/TopMenu.vue'
import MessageBody from '../components/MessageBody.vue'


import axios from 'axios'
//import api from "../services/UserService";

export default {
  name: 'App',
  components: {
    TopMenu,
    MessageBody
},
  data() {
      return {
          filter: '',
          selectedGroup: '120363048716161665@g.us',
          messageTime: 0,
          groups: [],
          sortBy: 'timestamp',
          sortDesc: true,
          fields: [
            {
              key: 'name',
              label: 'name',
              thStyle: { display: 'none' },
            }
          ],
          loading: false,
          isViewingMessage: false,
          isShowingSearchResult: false,
          record: [

          ]
      }
  },
  async mounted() {
    // get groups
    const groupResult = await this.fetchGroups();
    this.groups = groupResult.data.groups;

    // assign selected group to first group
    this.selectedGroup = this.groups[0].id;

    // handle popstate
    window.onpopstate = (event) => {
      if(event.state) {
        this.onBackToGroups();
      }
    }

    // set 10 second interval to fetch groups
    if(this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.interval = setInterval(async () => {
      if(this.isViewingMessage || this.isShowingSearchResult) {
        return;
      }
      const groupResult = await this.fetchGroups();
      let oldGroups = this.groups;
      this.groups = groupResult.data.groups;
      // compare old groups with new groups, if timestamp is different, show badge
      for(let i = 0; i < this.groups.length; i++) {
        let oldGroup = oldGroups.find(group => group.id === this.groups[i].id);
        if(oldGroup && oldGroup.timestamp !== this.groups[i].timestamp || !oldGroup) 
        {
          this.$bvToast.toast(this.groups[i].message, {
            title: this.groups[i].name,
            autoHideDelay: 5000,
            appendToast: true
          });
          this.groups[i].isNewMessage = true;
        }
        else if(oldGroup)
        {
          this.groups[i].isNewMessage = oldGroup.isNewMessage;
        }
      }

    }, 10000);
  },
  beforeDestroy () {
    window.removeEventListener('popstate', this.onPopState);
    clearInterval(this.interval);
  },

  watch: {
  },
  computed: {
    groupOptions: function() {
      return this.groups.map(group => {
        return { value: group.id, text: group.name, timestamp: group.timestamp, message: group.message, isNewMessage: group.isNewMessage };
      })
    },
    searchBtnText: function() {
      return this.isShowingSearchResult ? 'Back' : 'Search';
    },
    searchBtnClass: function() {
      return this.isShowingSearchResult ? 'secondary' : 'primary';
    }

  },
  methods: {
    async findMessage() {
      if(this.isShowingSearchResult) {
        this.isShowingSearchResult = false;
        const groupResult = await this.fetchGroups();
        this.groups = groupResult.data.groups;
      }
      else {
        this.isShowingSearchResult = true;
        const result = await axios.get('https://findhse.cc/whapi/searchmessages?keyword=' + this.filter);
        console.log(result.data);
        //transform result to groupOptions
        this.groups = result.data.messages.map(message => {
          return { id: message.chat_id, name: message.chat_name, timestamp: message.timestamp, message: message.message, isNewMessage: false };
        });
      }

    },
    async fetchGroups() {
      const result = await axios.get('https://findhse.cc/whapi/getgroups');
      return result;
    },
    async onChangeGroup() {
    },
    onBackToGroups() {
      this.isViewingMessage = false;
      setTimeout(() => {
        window.scrollTo(0, this.groupScrollPosition);
      }, 50);

    },
    formatDate(date) {
      // if time is same day, show time only
      let today = new Date();
      let messageDate = new Date(date * 1000);
      if(today.toDateString() === messageDate.toDateString()) {
        return new Date(date * 1000  + 8 * 60 * 60 * 1000).toISOString().substr(11, 8).replace('T', ' '); // GMT+8
      } else {
        return new Date(date * 1000 + 8 * 60 * 60 * 1000).toISOString().substr(0, 10).replace('T', ' '); // GMT+8
      }

    },
    formatMessage(message) {
      return message.message;
      // if(message.type === 'link_preview') {
      //   return message.message + ' ' + message.link;
      // } else {
      //   return message.message;
      // }
    },
    formatSubText(message) {
      if(!message) {
        return '';
      }
      else if(message.length > 30) {
        return message.substring(0, 30) + '...';
      } else {
        return message;
      }
    },
    onRowClicked(item) {
      this.isViewingMessage = true;
      this.groupScrollPosition = window.scrollY;

      // set selected group
      this.selectedGroup = item.value;
      this.groups.find(group => group.id === item.value).isNewMessage = false;
      this.messageTime = this.isShowingSearchResult ? item.timestamp : 0;

      // scroll page to bottom after 50ms
      if(this.isShowingSearchResult)
      {
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 500);
      }
    }
  }
}
</script>

<style>
  @import '../assets/styles/global.css';

  body {
    overscroll-behavior: none;
  }

  .user {
    display: flex;
    align-items: center;
  }

  .message {
    margin-top: 5px;
  }

  .media {
    margin-top: 5px;
  }



</style>