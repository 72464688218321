<template>
<div>
  <b-navbar  type="dark" variant="dark" fixed="bottom">
    <b-navbar-nav style="width: 100%" fill>
      <b-nav-item to="/newProperties" exact><feather type="home"></feather>新盤</b-nav-item>
      <b-nav-item to="/dashboard" exact><feather type="list"></feather>二手</b-nav-item>
      <b-nav-item to="/news" exact><feather type="info"></feather>新聞</b-nav-item>
      <b-nav-item to="/propertyGenerator" exact><feather type="message-square"></feather>盤紙</b-nav-item>
      <b-nav-item to="/newPropertyRecord" exact><feather type="smartphone"></feather>記錄</b-nav-item>
      <b-nav-item to="/message" exact><feather type="send"></feather>信息</b-nav-item>
      <b-nav-item to="/login"><feather type="settings"></feather>登出</b-nav-item>
    </b-navbar-nav>

  </b-navbar>
</div>
</template>

<style scoped>
a.router-link-exact-active {
  color: lightBlue !important;
}

</style>

<script>


    export default {
        name: 'TopMenu',
        props: [],
        components: {
        },
        data() {
          return {
          }
        },
        mounted() {
        }
    }
</script>